import { Button, Form, Modal, Select } from 'antd';
import { useForm, useSelect } from '@refinedev/antd';
import { useMemo } from 'react';
import type {
  BaseOption,
  HttpError,
  useModalReturnType
} from '@refinedev/core';
import type { IAgent } from 'interfaces/agents';

interface AddManagerModalModalProps {
  modal: useModalReturnType;
  loading: boolean;
  selectedAgents: IAgent[];
  onSubmit: (agentId: string) => void;
}

interface FormData {
  agentId: string;
}

export const AddManagerModal = ({
  modal,
  loading,
  selectedAgents,
  onSubmit
}: AddManagerModalModalProps) => {
  const { form, formProps } = useForm<FormData, HttpError, FormData>();

  const { selectProps, queryResult } = useSelect<IAgent>({
    resource: 'agents',
    optionLabel: 'name',
    optionValue: 'id',
    fetchSize: 50,

    onSearch: value => [
      {
        field: 'filter[by_name]',
        operator: 'eq',
        value: value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  const agentsListWithoutSelected = useMemo(() => {
    const data: BaseOption[] = [];

    if (queryResult.data && queryResult.data.data.length > 0) {
      queryResult.data?.data.filter((agent: IAgent) => {
        if (
          !selectedAgents.some(selectedAgent => selectedAgent.id === agent.id)
        ) {
          data.push({
            value: agent.id,
            label: agent.name
          });
        }
      });
    }

    return data;
  }, [queryResult.data, selectedAgents]);

  return (
    <Modal
      title="Adicionar docente à equipe da turma"
      open={modal.visible}
      onCancel={modal.close}
      destroyOnClose
      footer={[
        <Button key="back" onClick={() => modal.close()}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          onClick={form.submit}
          loading={loading}
          disabled={loading}
        >
          Adicionar docente
        </Button>
      ]}
    >
      <Form
        {...formProps}
        layout="vertical"
        onFinish={({ agentId }) => onSubmit(agentId)}
      >
        <Form.Item label="Usuário" name="agentId">
          <Select {...selectProps} options={agentsListWithoutSelected} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
