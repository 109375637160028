import FaqCoordinators from './FaqCoordinators';
import ForumReports from './ForumReports';
import _academic from './_academic';
import _admin from './_admin';
import _b2b from './_b2b';
import _certificate_management from './_certificate_management';
import _communication from './_communication';
import _configurations from './_configurations';
import _courses from './_courses';
import _deal from './_deal';
import _ecommerce from './_ecommerce';
import _evaluations from './_evaluations';
import _finance from './_finance';
import _sales from './_sales';
import _secretary from './_secretary';
import _students_teachers from './_students_teachers';
import _teaching_resources from './_teaching_resources';
import academic_record_equivalencies from './AcademicRecordEquivalencies';
import academic_records from './academic_records/AcademicRecords';
import action_logs from './ActionLogs';
import agents from './Agents';
import assessment_questions from './assessments/AssessmentQuestions';
import assessment_user_attempts from './assessments/UserAttempts';
import assessments from './assessments/Assessments';
import assessments_attepts from './assessments/AssessmentsAttempts';
import assignment_attempts from './assignments/AssignmentAttempts';
import assignments from './assignments/Assignments';
import bonuses from './Bonuses';
import bulk_bonuses from './BulkBonuses';
import bulk_user_suspends from './BulkUserSuspends';
import bulk_users from './BulkUsers';
import certificates_free_courses from './CertificateFreeCourses';
import certificates_regulated_courses from './CertificatesRegulatedCourses';
import certifying_institutions from './Certifiers';
import classrooms from './Classrooms';
import comments from './Comments';
import companies from './Companies';
import contents from './libraries/Contents';
import contracts from './Contracts';
import coupons from './Coupons';
import course_enrollment from './users/CourseEnrollment';
import courses from './Courses';
import discussion_threads from './DiscussionThreads';
import document_templates from './DocumentTemplates';
import documentation_association from './DocumentationAssociation';
import documentations from './Documentations';
import ecommerce_divisions from './EcommerceDivisions';
import ecommerce_profit_centers from './EcommerceProfitCenters';
import epimetheus_groups from './TagGroups';
import epimetheus_tags from './Tags';
import equivalencies from './Equivalencies';
import fees from './Fees';
import forum from './Forum';
import graduate_courses from './GraduateCourses';
import graduate_disciplines from './GraduateDisciplines';
import graduate_enrollment from './users/GraduateEnrollment';
import graduate_modules from './GraduateModules';
import instructors from './Instructors';
import interactive_classes from './InteractiveClasses';
import libraries from './Libraries';
import log_deleted_users from './LogDeletedUsers';
import manualOrders from './ManualOrder';
import noticeBoards from './NoticeBoards';
import offer_campaign_assets from './OfferCampaignAssets';
import offer_campaigns from './OfferCampaigns';
import offer_gateway_rules from './OfferGatewayRules';
import order_not_refunds from './OrderNotRefunds';
import order_payment_plan from './OrderPaymentPlan';
import order_payment_refunds from './OrderPaymentRefunds';
import order_refunds from './OrderRefunds';
import orders from './Orders';
import payment_method_rules from './PaymentMethodRules';
import payment_plan_deals_discounts from './discount_campaings/PaymentPlanDealsDiscounts';
import payment_plans from './PaymentPlans';
import pending_certificates from './PendingCertificates';
import personifications from './Personifications';
import possessions from './Possession';
import product_bundles from './ProductBundles';
import product_categories from './ProductCategories';
import product_sets from './ProductSets';
import products from './Products';
import professors from './Professors';
import prometheus_lives from './prometheus/Lives';
import prometheus_videos from './prometheus/Videos';
import reports from './Reports';
import roles from './Roles';
import signaturepartners from './SignaturePartners';
import signatures from './Signatures';
import testimonials from './Testimonials';
import user_assignments from './assignments/UserAttempts';
import users from './users/Users';
import {
  IAppResource,
  IResource,
  IResourceAndRoutes
} from 'interfaces/resources';
import { getTenant } from 'services/tenants';

export const RESOURCES: IResourceAndRoutes[] = [
  // Alunos e docentes
  _students_teachers,
  users,
  course_enrollment,
  graduate_enrollment,
  professors,
  personifications,

  // Cursos
  _courses,
  courses,
  graduate_courses,
  graduate_modules,
  graduate_disciplines,
  classrooms,

  // Recursos de Ensino
  _teaching_resources,
  libraries,
  epimetheus_groups,
  epimetheus_tags,
  contents,
  interactive_classes,
  prometheus_videos,
  prometheus_lives,

  // Avaliacoes
  _evaluations,
  assessment_questions,
  assessments,
  assessment_user_attempts,
  assignments,
  user_assignments,
  assessments_attepts,
  assignment_attempts,

  // Comunicacao
  _communication,
  noticeBoards,
  comments,
  discussion_threads,
  FaqCoordinators,
  reports,
  ForumReports,
  forum,

  // Secretaria
  _secretary,
  document_templates,
  documentations,
  documentation_association,
  equivalencies,
  academic_records,
  academic_record_equivalencies,

  // Gestao de certificados
  _certificate_management,
  certificates_free_courses,
  certificates_regulated_courses,
  signatures,
  signaturepartners,
  certifying_institutions,
  pending_certificates,

  // Loja Online
  _ecommerce,
  products,
  offer_campaigns,
  product_bundles,
  product_sets,
  product_categories,
  offer_campaign_assets,
  instructors,
  testimonials,
  possessions,

  // Vendas
  _sales,
  coupons,
  manualOrders,
  orders,
  ecommerce_divisions,
  bonuses,
  order_payment_plan,

  // Financeiro
  _finance,
  offer_gateway_rules,
  ecommerce_profit_centers,
  order_refunds,
  order_not_refunds,
  order_payment_refunds,

  // Planos de pagamento
  _deal,
  payment_plans,
  payment_plan_deals_discounts,
  fees,
  payment_method_rules,

  // B2B
  _b2b,
  companies,
  contracts,

  // Configuracoes
  _configurations,
  agents,
  roles,
  log_deleted_users,
  action_logs,

  bulk_bonuses,
  bulk_users,
  bulk_user_suspends
];

export const Routes = [...users.routes, ...instructors.routes];

const tenant = getTenant();

export const getResourcesAndRoutes = (): IResourceAndRoutes => {
  const routes: JSX.Element[] = [];
  const resource = RESOURCES.map(
    (resourceAndRoutes: IResourceAndRoutes): IAppResource | undefined => {
      const resource = resourceAndRoutes.resource as IResource;
      routes.push(...resourceAndRoutes.routes);

      if (
        tenant.disabledFeatures?.includes(resource.name) ||
        tenant.disabledFeatures?.includes(resource.parentName || '') ||
        resource.onlyPermission
      ) {
        return;
      }

      return {
        name: resource.name,
        list: resource.list,
        show: resource.show,
        edit: resource.edit,
        create: resource.create,
        icon: resource.icon,
        parentName: resource.parentName,
        options: {
          label: resource.label,
          route: `${resource.parentName}/${resource.route || resource.name}`,
          hide: resource.hide
        }
      };
    }
  ).filter(
    (resource: IAppResource | undefined): resource is IAppResource => !!resource
  );

  return {
    resource,
    routes
  };
};
